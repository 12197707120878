<template>
  <ion-card id="lieu" class="horaires-home-page" color="secondary">
    <ion-item
      lines="none"
      href="https://goo.gl/maps/WYeJcW1ZCdAfYrzy8"
      detail
      color="secondary"
    >
      <ion-label class="ion-text-wrap">
        <h2>Halle des Expositions</h2>
        Rue Émile-Boéchat 60, Delémont
      </ion-label>

    </ion-item >
  </ion-card>
</template>

<script>
import {IonCard, IonItem, IonLabel} from "@ionic/vue";

export default {
  name: 'Lieu',
  components: {
    IonCard,
    IonItem,
    IonLabel
  },
}
</script>

<style scoped>
  #lieu {
    margin-top: 1rem;
    /*background-image: url("~@/assets/photos/halle-expo-delemont.jpg");*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    /* background-attachment: fixed; */

    /*background-image: url("~@/assets/photos/halle-expo-delemont.jpg");

    background-blend-mode: overlay;*/
  }

  #lieu ion-label h2 {
    font-size: 1rem;
    font-weight:700;
  }
</style>
