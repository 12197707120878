<template>
  <ion-page>
    <ion-content>

      <LinkGrid />

      <LogoSalon />

      <h2 class="ion-margin">
        Un moyen étincelant d'explorer l'univers de la formation
      </h2>

      <SimpleCard>Mesures sanitaires selon dispositions du moment</SimpleCard>

      <Horaires/>

      <Lieu />

      <green-audio-player
          :song="audio"
          titre="Présentation de l'application"
          ref="audio"
          class="green-audio"
      />

      <Orientations />

      <timeline />

      <CreatedBy />
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue'

import LinkGrid from '@/components/LinksGrid'
import GreenAudioPlayer from "@/components/GreenAudioPlayer";
import LogoSalon from '@/components/LogoSalon'
import CreatedBy from '@/components/CreatedBy'
import Horaires from "@/components/Horaires";
import Timeline from "@/components/Timeline";
import Lieu from "@/components/Lieu";
import SimpleCard from "@/components/SimpleCard";
import Orientations from "@/components/Orientations";
export default {
  name: 'Home',
  data () {
    return {
      audio: require('@/assets/audio/intro.mp3')
    }
  },
  /* beforeRouteLeave (to, from, next) {
    this.$refs.audio.togglePlay()
    next()
  }, */
  components: {
    Orientations,
    SimpleCard,
    Lieu,
    Timeline,
    Horaires,
    IonPage,
    IonContent,
    CreatedBy,
    LogoSalon,
    LinkGrid,
    GreenAudioPlayer
  }
}
</script>

<style scoped lang="sass">
  h2
    color: var(--ion-color-primary)
    text-align: center
    font-size: 1.25em

  img.macon
    display: block
    max-width: 100%

</style>
