<template>
  <ion-card :color="bgColor">
    <ion-card-content>
        <slot></slot>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {IonCard, IonCardContent} from "@ionic/vue";

export default {
  name: 'SimpleCard',
  props: {
    bgColor: {
      type: String,
      default: 'primary'
    },
    color: {
      type: String,
      default: 'primary-contrast'
    },
  },
  components: {
    IonCard,
    IonCardContent
  },
}
</script>

<style scoped lang="sass">
  ion-card-content
    padding: 10px 20px
    font-size: 1.2rem
    text-align: center

</style>
