<template>
  <div id="logoSalon" class="ion-text-center ion-margin">
    <img src="@/assets/salon-logo-horizontal.svg"
         alt="Loga Salon de la formation">
  </div>
</template>

<script>
export default {
  name: 'LogoSalon'
}
</script>

<style scoped>
  #logoSalon img {
    padding-top: 50px;
  }
</style>
