<template>
  <ion-grid class="contact-icon">
    <ion-row align-items-center class="ion-text-center">
      <ion-col>
        <a href="https://www.salon-formation.ch">
          <ion-icon :icon="globeOutline"></ion-icon>
        </a>
      </ion-col>
      <ion-col>
        <a href="https://www.facebook.com/SalonInterjurassienDeLaFormation/">
          <ion-icon :icon="logoFacebook"></ion-icon>
        </a>
      </ion-col>
      <ion-col>
        <a href="https://www.instagram.com/salon_formation/">
          <ion-icon :icon="logoInstagram"></ion-icon>
        </a>
      </ion-col>
      <ion-col>
        <a href="https://goo.gl/maps/WYeJcW1ZCdAfYrzy8">
          <ion-icon :icon="navigate"></ion-icon>
        </a>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { globeOutline, logoFacebook, logoInstagram, navigate } from 'ionicons/icons'
import { IonGrid, IonRow, IonCol, IonIcon} from '@ionic/vue'

export default {
  name: 'LinksGrid',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonIcon
  },
  setup() {
    return { globeOutline, logoInstagram, logoFacebook, navigate };
  },
}
</script>

<style scoped>
  .contact-icon,
  .contact-icon ion-col{
    margin: 0;
    padding: 0;
  }

  .contact-icon {
    position: absolute;
    top: 30px;
    right: 13px;
    width: 200px;
  }

  .contact-icon a,
  .contact-icon a:visited {
    text-align: right;
    display: block;
    padding-top: 10px;
    font-size: 32px;
    color: var(--ion-color-dark);
    text-decoration: none;
  }
</style>
