<template>
    <ion-card id="temps-forts">
      <ion-card-header>
        <ion-card-subtitle>
          Les centres d’orientation sont également disponibles
          pour répondre à toutes vos questions.
        </ion-card-subtitle>
      </ion-card-header>
      <ion-card-content class="intro">
        <NavigateButton id-ilot="40">Emplacement du stand</NavigateButton>
        <h2>Pour le Jura</h2>
        <p>Centre d’orientation scolaire et professionnelle (COSP)</p>

        <ion-button
            color="primary"
            href="https://www.jura.ch/cosp"
        >
          <ion-icon :icon="linkSharp" slot="start"></ion-icon>
          <ion-label>Site du COSP</ion-label>
        </ion-button>

        <h2>Pour la Berne francophone</h2>
        <p>Centre d’orientation professionnelle (OP)</p>
        <ion-button
            color="primary"
            href="https://www.biz.bkd.be.ch/fr/start.html"
        >
          <ion-icon :icon="linkSharp" slot="start"></ion-icon>
          <ion-label>Site de l'OP</ion-label>
        </ion-button>


        <h2>Faire des stages ?</h2>
        <h3>Le meilleur moyen pour se familiariser avec un ou
          plusieurs métiers</h3>

        <ion-button
          color="primary"
          href="https://www.mon-stage.ch/"
        >
          <ion-icon :icon="linkSharp" slot="start"></ion-icon>
          <ion-label>Trouver une place de stage</ion-label>
        </ion-button>

        <h2>Trouver une place d'apprentissage ?</h2>
        <h3>Places d'apprentissages disponibles :</h3>

        <ion-button
          color="primary"
          href="https://www.mon-app.ch/default.asp#Places"
        >
          <ion-icon :icon="linkSharp" slot="start"></ion-icon>
          <ion-label>Canton du Jura</ion-label>
        </ion-button>

        <ion-button
          color="dark"
          href="http://www.be.ch/placesappr"
        >
          <ion-icon :icon="linkSharp" slot="start"></ion-icon>
          <ion-label>Canton de Berne</ion-label>
        </ion-button>
      </ion-card-content>
    </ion-card>
</template>

<script>
import {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardHeader,
    IonButton,
    IonLabel,
    IonIcon
} from '@ionic/vue'

import {
  linkSharp,
} from 'ionicons/icons'
import NavigateButton from "@/components/NavigateButton";


export default {
  name: 'Orientations',
  setup() {
    return { linkSharp };
  },
  components: {
    NavigateButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardHeader,
    IonButton,
    IonLabel,
    IonIcon
  },
}
</script>

<style scoped lang="sass">
ion-card
  margin-top: 2rem

  ion-card-header
    margin-bottom: 1rem
    padding-bottom: 0

    ion-card-subtitle
      font-size: 1rem
      color: var(--ion-color-primary)

  h2
    font-size: 1.5rem
    color: var(--ion-color-dark)
    margin: 1.5rem 0 .25rem

  h3
    font-size: 1.1rem
    margin: 0 0 .75rem

  p
    margin: .5rem 0
    font-size: 1rem
</style>
