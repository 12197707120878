<template>
  <ion-button
    color="dark"
    @click="goToCarteIlot(idIlot)"
  >
    <ion-icon :icon="navigateSharp" slot="start"></ion-icon>
    <ion-label><slot></slot></ion-label>
  </ion-button>
</template>

<script>
import { navigateSharp } from "ionicons/icons";
import {IonIcon, IonLabel, IonButton} from "@ionic/vue";
import {mapActions} from "vuex";

export default {
  name: 'NavigateButton',
  components: {
    IonIcon,
    IonButton,
    IonLabel
  },
  setup() {
    return {
      navigateSharp
    };
  },
  methods:  {
    ...mapActions(['setCarteIlot']),
    goToCarteIlot (idIlot) {
      this.setCarteIlot(idIlot)
      this.$router.push({ name: 'carte' })
    },
  },
  props: {
    idIlot: { required: true }
  }
}
</script>

<style scoped>

</style>
