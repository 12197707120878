<template>
  <ion-card id="horaires" class="horaires-home-page">
    <ion-card-header>
      <ion-card-title>Du 23 au 27 mars 2022</ion-card-title>
    </ion-card-header>

    <ion-card-content class="no-padding-top">
      <ion-card-subtitle>Heures d’ouverture</ion-card-subtitle>
      <ion-grid>
        <ion-row>
          <ion-col>
            Mercredi
          </ion-col>
          <ion-col>
            8h – 17h
          </ion-col>
          <ion-col></ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            Jeudi
          </ion-col>
          <ion-col>
            8h – 20h
          </ion-col>
          <ion-col></ion-col>
        </ion-row>
        <ion-row class="notes">
          <ion-col><em>&gt; Concours des métiers de bouche</em></ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            Vendredi
          </ion-col>
          <ion-col>
            8h – 20h
          </ion-col>
          <ion-col></ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            Samedi
          </ion-col>
          <ion-col>
            9h – 18h
          </ion-col>
          <ion-col></ion-col>
        </ion-row>
        <ion-row class="notes">
          <ion-col><em>&gt; Remise des prix des concours</em></ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            Dimanche
          </ion-col>
          <ion-col>
            10 – 16h
          </ion-col>
          <ion-col></ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardHeader, IonCardSubtitle, IonCardTitle,
  IonCol,
  IonGrid,
  IonRow
} from "@ionic/vue";

export default {
  name: 'Horaires',
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonGrid,
    IonCol,
    IonRow
  }
}
</script>

<style scoped lang="sass">

#horaires
  margin-top: 5px
  /*background-image: url("~@/assets/photos/salon-hor.jpg");*/
  background-size: cover
  background-repeat: no-repeat
  background-position: right center
  /* background-attachment: fixed; *

  background-image: url("~@/assets/photos/fusee.png"),linear-gradient(to right,var(--ion-color-primary), rgba(0,0,0,0))
  // background-image: url("~@/assets/photos/fusee.png"), linear-gradient(to right,var(--ion-color-primary), var(--ion-color-secondary))
  background-blend-mode: multiply
  // background-image: url("~@/assets/photos/fusee.png")

  ion-card-header
      padding-bottom: 0

  ion-card-title,
  ion-card-subtitle
    color: var(--ion-color-primary-contrast)
    text-shadow: 0 0 3px rgba(0, 0, 0, .3)

  ion-card-content
    padding-top: 20px
    font-weight: bold
    color: var(--ion-color-primary-contrast)
    text-shadow: 0 0 2px rgba(0, 0, 0, .3)
    // background: rgb(255,255,255)
    // background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 30%)

  ion-card-content ion-row ion-col
    font-size: 1em
    padding: 2.5px

  ion-card-content ion-row.notes ion-col
    padding-top: 0
    padding-left: 1em
    font-size: .8em

</style>
