<template>
  <ion-card id="temps-forts">
    <ion-card-header>
      <ion-card-title>Temps forts</ion-card-title>
    </ion-card-header>

    <ul class="timeline">
      <li class="event">
        <h3>SwissSkills constructeur·trice·s de routes</h3>
        <p>12 équipes seront engagées dans l’épreuve du championnat
          des SwissSkills des constructeur·trice·s de routes
          et 6 seront sélectionnées. Il y aura 2 participant·e·s par équipe.</p>
        <NavigateButton id-ilot="58">Emplacement du concours</NavigateButton>
        <div>
          <ion-button
              color="warning"
              href="https://halledesmacons.ch/"
          >
            <ion-icon :icon="linkSharp" slot="start"></ion-icon>
            <ion-label>halledesmacons.ch</ion-label>
          </ion-button>
        </div>
      </li>
      <li class="event">
        <h3>SwissSkills microtechnique</h3>
        <p>36 apprenti·e·s de 6 régions (GE, VD, NE, JU, JB et BE) seront
          engagé·e·s dans les épreuves de qualification romande aux SwissSkills
          pour deux métiers de la microtechnique. Il y aura un·e gagnant·e par
          région, donc 12 gagnant·e·s au total (pour 2 métiers et 6 régions).
        </p>
        <NavigateButton id-ilot="25">Emplacement du concours</NavigateButton>
        <div>
          <ion-button
              color="warning"
              href="https://metiers-horlogerie.ch/"
          >
            <ion-icon :icon="linkSharp" slot="start"></ion-icon>
            <ion-label>metiers-horlogerie.ch</ion-label>
          </ion-button>
        </div>
      </li>
      <li class="event">
        <h3>Tech’en tête</h3>
        <p>Tech’ en tête est un programme d’activités qui permet de découvrir
          les métiers de l’industrie d’aujourd’hui et de demain.
          </p>
        <div>
          <ion-button
              color="primary"
              href="https://www.bepog.ch/"
          >
            <ion-icon :icon="linkSharp" slot="start"></ion-icon>
            <ion-label>#bepog</ion-label>
          </ion-button>

          <ion-button
              color="warning"
              href="https://www.techentete.ch/"
          >
            <ion-icon :icon="linkSharp" slot="start"></ion-icon>
            <ion-label>Tech’en tête</ion-label>
          </ion-button>
        </div>


        <NavigateButton id-ilot="18">Emplacement Tech’en tête</NavigateButton>
      </li>
      <li class="event">
        <h3>SwissSkills menuisier·ère·s, charpentier·ère·s, ébénistes</h3>
        <p>Championnats romands des menuisier·ère·s, charpentier·ère·s
          et ébénistes en vue des championnats suisses.</p>
        <NavigateButton id-ilot="57">Emplacement du concours</NavigateButton>
        <div>
          <ion-button
              color="warning"
              href="https://www.frecem.ch/"
          >
            <ion-icon :icon="linkSharp" slot="start"></ion-icon>
            <ion-label>frecem.ch</ion-label>
          </ion-button>
        </div>
      </li>
      <li class="event">
        <h3>Finale du Poivrier d’argent</h3>
        <p>
          Concours des meilleur·e·s apprenti·e·s cuisinier·ère·s CFC
          de Suisse romande et du Tessin.
        </p>
        <NavigateButton id-ilot="85">Emplacement du concours</NavigateButton>
        <div>
          <ion-button
              color="warning"
              href="https://www.metiershotelresto.ch/page-d-accueil"
          >
            <ion-icon :icon="linkSharp" slot="start"></ion-icon>
            <ion-label>metiershotelresto.ch</ion-label>
          </ion-button>
        </div>
      </li>
      <li class="event">
        <h3>Métiers de bouche</h3>
        <p>
          Concours des apprenti·e·s des métiers de bouche.
        </p>
        <NavigateButton id-ilot="86">Emplacement du concours</NavigateButton>
      </li>
      <li class="event">
        <h3>Aménagements paysagers</h3>
        <p>
          Animations des horticulteur·trice·s paysagistes CFC BEJUNE.
        </p>
        <NavigateButton id-ilot="64">Emplacement de l'animation</NavigateButton>

        <div>
          <ion-button
              color="warning"
              href="https://www.jardinsuisse.ch/fr/homepage/"
          >
            <ion-icon :icon="linkSharp" slot="start"></ion-icon>
            <ion-label>jardinsuisse.ch</ion-label>
          </ion-button>
          <ion-button
              color="warning"
              href="https://www.youtube.com/watch?v=FAkS-G9IHQQ"
          >
            <ion-icon :icon="filmOutline" slot="start"></ion-icon>
            <ion-label>Vidéo</ion-label>
          </ion-button>
        </div>

      </li>
      <li class="event">
        <h3>Prix « Un pas vers l’Égalité »</h3>
        <p>
          Le thème de l’égalité sera également mis à l’honneur par
          l’organisation d’un concours qui récompensera
          le stand favorisant de manière concrète
          la promotion de l’égalité entre femmes et hommes.
        </p>
        <div>
          <ion-button
              color="warning"
              href="https://kode.ch/salon2020/EGA_REGLEMENT-CONCOURS.pdf"
          >
            <ion-icon :icon="linkSharp" slot="start"></ion-icon>
            <ion-label>Règlement</ion-label>
          </ion-button>
        </div>
      </li>
      <li class="event">
        <h3>Cérémonie de remise des prix</h3>

        <p>
          <strong>Samedi 26 mars 2022, à 18h30</strong><br>
          Cinemont, Salle Lumière, Delémont<br>
        </p>
        <div class="red">Sur invitation uniquement</div>
        <p>Animations avec Carrousel</p>
      </li>
    </ul>
  </ion-card>
</template>

<script>
import NavigateButton from './NavigateButton'
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle, IonIcon, IonLabel
} from "@ionic/vue";
import { linkSharp, filmOutline } from "ionicons/icons";

export default {
  name: 'Timeline',
  components: {
    NavigateButton,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonButton,
    IonLabel,
    IonIcon
  },
  setup() {
    return {
      linkSharp, filmOutline
    };
  }
}
</script>

<style lang="scss">
/* Source : https://codepen.io/alanhouser/pen/aErrQJ?editors=0100#0 */
/* Variables */

$background: white;

$color-primary: #e30613;
$color-light: white;
$color-dark: #373636;
$color-dim: #6c6d6d;
$spacing: 20px;
$radius: 4px;

$date: 120px;
$dotborder: 4px;
$dot: 11px;
$line: 4px;

/*$font-title: 'Saira', sans-serif;
$font-text: 'Chivo', sans-serif;*/

/* Base */

#temps-forts {
  background: $background;

  /* Timeline */
  .red {
    color: $color-primary;
  }
  .timeline {
    list-style: none;

    /*font-family: $font-text;*/
    font-size: 1rem;
    line-height: 1.4em;

    text-align: left;

    border-left: $line solid $color-primary;
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
    /*background: rgba($color-dark, 3%);*/
    /*margin: 0 0 0 80px;
    width: calc(100% - 80px);*/
    margin: 0 0 0 $spacing;
    position: relative;
    padding: 0 10px 0 $spacing;

    h2, h3 {
      color: $color-dark;
    }

    .event {
      border-bottom: 1px dashed rgba($color-dark, 10%);
      padding-bottom: ($spacing * 0.5);
      margin-bottom: $spacing;
      position: relative;

      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }

      &:before, &:after {
        position: absolute;
        display: block;
        top: 0;
      }

      &:before {
        left: ((($date * 0.6) + $spacing + $line + $dot + ($dotborder * 2)) * 1.5) * -1;
        color: $color-dark;
        content: attr(data-date);
        text-align: right;
        font-weight: 100;
        font-size: 0.9em;
        min-width: $date;
        /*font-family: $font-title;*/
      }

      &:after {
        box-shadow: 0 0 0 $dotborder rgba($color-primary, 100%);
        left: ($spacing + $line + ($dot * 0.35)) * -1;
        background: lighten($background, 5%);
        border-radius: 50%;
        height: $dot;
        width: $dot;
        content: "";
        top: 5px;
      }
    }
  }
}
</style>
